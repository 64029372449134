<template>
  <b-card>
    <div class="d-flex border-b-2 pb-1">
      <div class="d-flex align-items-center my-2">
        <b-button
          variant="primary"
          size="sm"
          class="mr-1 rounded-lg p-0"
          @click="$router.push('/opsional-feature/notifikasi-wa-premium')"
        >
          <feather-icon
            size="2x"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <div class="text-black text-[24px] font-[600]">
          Riwayat Notifikasi
        </div>
      </div>
    </div>

    <div class="d-inherit d-lg-flex justify-between my-1">
      <span class="d-flex">
        <span class="align-self-center">Total Notifikasi : {{ countNotification }}</span>
        <img
          v-b-tooltip.hover
          src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
          title="Riwayat notifikasi yang sudah lebih dari 30 hari akan otomatis terhapus"
          class="mx-1 w-6"
          alt=""
        >
      </span>
      <span class="d-flex">
        <v-select
          v-model="setFilter"
          class="w-[15rem]"
          :options="filter"
          label="name"
          :clearable="false"
          @input="fetchData()"
        />
        <date-range-picker
          ref="picker"
          v-model="dateRangeFilter"
          :locale-data="locale"
          :ranges="ranges"
          :opens="'left'"
          class="mx-2 w-[20rem]"
          control-container-class="form-control custom-class-datepicker"
          @update="fetchData()"
        >
          <template
            v-slot:input="picker"
          >
            <div
              class="d-flex justify-content-between align-items-center text-black text-[12px]"
            >
              <div
                class="mr-1"
              >
                <span
                  v-if="DDMMYYYY(picker.startDate) === DDMMYYYY(today) && DDMMYYYY(picker.endDate) === DDMMYYYY(today)"
                >Hari Ini</span>
                <span
                  v-else-if="DDMMYYYY(picker.startDate) === DDMMYYYY(last7)"
                >7 Hari Terakhir</span>
                <span
                  v-else-if="DDMMYYYY(picker.startDate) === DDMMYYYY(last30)"
                >30 Hari Terakhir</span>
                <span
                  v-else-if="dateRangeFilter.startDate !== null && DDMMYYYY(picker.startDate) !== DDMMYYYY(today) && DDMMYYYY(picker.startDate) !== DDMMYYYY(last7) && DDMMYYYY(picker.startDate) !== DDMMYYYY(last30)"
                >Custom Tanggal</span>
                <span
                  v-else
                  class="d-flex"
                >
                  <span class="mr-[10rem]">
                    Pilih Tanggal
                  </span>
                  <img
                    src="https://storage.googleapis.com/komerce/assets/icons/date-picker-icon.svg"
                  >
                </span>
              </div>
            </div>
          </template>
        </date-range-picker>
        <div class="w-[15rem] mr-1">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="search"
              placeholder="Nama Customer"
              class="form-control custom-class-search"
              @input="fetchData()"
            />
          </b-input-group>
        </div>
      </span>
    </div>

    <b-overlay
      variant="light"
      :show="loadTable"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <b-table
        id="table"
        responsive
        show-empty
        empty-text="Tidak ada data untuk ditampilkan."
        :items="items"
        :fields="fields"
        :busy="loadingTable"
      >
        <template #cell(date)="data">
          <span>{{ DDMMYYYY(data.item.date_log) }}</span><br>
          <span class="text-muted">{{ TIME(data.item.date_log) }}</span>
        </template>
        <template #cell(customer_phone)="data">
          <div class="d-flex">
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg"
              alt="icon-wa"
              type="button"
              @click="sendMessage(data.item.customer_phone)"
            >
            <img
              class="mx-1"
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/copy.svg"
              alt="icon-copy"
              type="button"
              @click="copyPhone(data.item.customer_phone)"
            >
            <span>0{{ data.item.customer_phone }}</span>
          </div>
        </template>
        <template #cell(type_notification)="data">
          <span
            class="font-medium p-[5px] rounded-lg"
          >Notifikasi {{ data.item.type_notification === 'pickup' ? 'Pickup' : 'COD' }}</span>
        </template>
        <template #cell(status)="data">
          <span
            class="font-medium p-[5px] rounded-lg"
            :class="data.item.status === 'success' ? 'text-success bg-[#DCF3EB]' : 'text-danger bg-[#FCBEBE]'"
          >{{ data.item.status === 'success' ? 'Terkirim' : 'Gagal' }}</span>
        </template>
      </b-table>
    </b-overlay>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { mapState } from 'vuex'
import {
  today,
  last7,
  last30,
  formatYmd,
} from '@/store/helpers'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { DDMMYYYY, TIME } from '@/libs/formatDate'

export default {
  components: {
    vSelect,
    DateRangePicker,
  },
  data() {
    return {
      countNotification: 0,
      setFilter: { value: '', name: 'Semua Status' },
      filter: [
        { value: '', name: 'Semua Status' },
        { value: 'Terkirim', name: 'Terkirim' },
        { value: 'Gagal', name: 'Gagal' },
      ],
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
      },
      today,
      last7,
      last30,
      formatYmd,
      DDMMYYYY,
      TIME,
      ranges: {
        'Hari Ini': [today, today],
        '7 Hari Terakhir': [last7, today],
        '30 Hari Terakhir': [last30, today],
        'Custom Tanggal': [null, today],
      },
      dateRangeFilter: {
        startDate: last30,
        endDate: today,
      },

      loadTable: false,
      fields: [
        {
          key: 'date',
          label: 'Tanggal',
          thClass: 'align-middle text-black text-black',
          tdClass: 'align-top',
        },
        {
          key: 'sender_phone',
          label: 'Nomor Pengirim',
          thClass: 'align-middle text-black',
          tdClass: 'align-top',
        },
        {
          key: 'customer_name',
          label: 'Nama Customer',
          thClass: 'align-middle text-black',
          tdClass: 'align-top',
        },
        {
          key: 'customer_phone',
          label: 'Nomor Customer',
          thClass: 'align-middle text-black',
          tdClass: 'align-top',
        },
        {
          key: 'type_notification',
          label: 'Jenis Notifikasi',
          thClass: 'align-middle text-black',
          tdClass: 'align-top',
        },
        {
          key: 'status',
          label: 'Status',
          thClass: 'align-middle text-black',
          tdClass: 'align-top',
        },
      ],
      items: [],

      limit: 50,
      offset: 0,
      isLastOrder: false,
      loadingTable: false,
    }
  },
  computed: {
    ...mapState('whatsapp', ['connected_wa']),
  },
  async mounted() {
    if (!this.connected_wa) {
      this.$router.push({ name: 'dashboard-whatsapp' })
    }
    if (this.$route.params.card === 'sending') {
      this.setFilter = { value: 'Terkirim', name: 'Terkirim' }
    } else if (this.$route.params.card === 'failed') {
      this.setFilter = { value: 'Gagal', name: 'Gagal' }
    } else {
      this.setFilter = { value: '', name: 'Semua Status' }
    }
    await this.fetchData()
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('table').offsetHeight && !this.loadingTable) {
        this.getNextData()
      }
    }
  },
  methods: {
    fetchData() {
      this.loadingTable = true
      this.offset = 0
      this.$http_komship.get('/v2/wap/log-notification', {
        params: {
          filter: this.setFilter.value,
          start_date: this.formatYmd(this.dateRangeFilter.startDate),
          end_date: this.formatYmd(this.dateRangeFilter.endDate),
          search: this.search,
          limit: this.limit,
          offset: this.offset,
        },
      })
        .then(res => {
          const { data } = res.data
          this.items = data.data
          this.countNotification = data.total
          this.offset = data.length
          this.loadingTable = false
          if (this.items.length < this.limit) {
            this.isLastOrder = true
          } else {
            this.isLastOrder = false
          }
        })
        .catch(() => {
          this.loadingButtonPrintLabel = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'Gagal load data!',
              variant: 'danger',
            },
          })
        })
    },
    getNextData() {
      if (!this.isLastOrder) {
        this.loadingTable = true
        this.$http_komship.get('/v2/wap/log-notification', {
          params: {
            filter: this.setFilter.value,
            start_date: this.formatYmd(this.dateRangeFilter.startDate),
            end_date: this.formatYmd(this.dateRangeFilter.endDate),
            search: this.search,
            limit: this.limit,
            offset: this.offset,
          },
        })
          .then(result => {
            const { data } = result.data
            this.items.push(...data)
            this.offset += data.length
            this.loadingTable = false
            if (data.length < this.limit) {
              this.isLastOrder = true
            }
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal load data!',
                variant: 'danger',
              },
            })
          })
      }
    },
    copyPhone(data) {
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(data)

      /* Alert the copied text */
      this.$toast({
        component: ToastificationContent,
        props: {
          title: ` Nomor ${data} berhasil dicopy`,
          icon: 'AlertCircleIcon',
          variant: 'warning',
        },
      }, 1000)
    },
    sendMessage(number) {
      window.open(`https://wa.me/62${number}`, '_blank')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.custom-class-datepicker {
  height: 2.71rem !important;
  padding-top: 10px;
}
.custom-class-search {
  height: 2.71rem !important;
}
</style>
